var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: { width: "100%" },
          attrs: {
            rules: _vm.rules,
            model: _vm.temp,
            "label-position": "left",
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-tabs",
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Основное" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Код товара в 1С" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.temp.code,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "code", $$v)
                          },
                          expression: "temp.code"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Наименование" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.temp.name,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "name", $$v)
                          },
                          expression: "temp.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Описание" } },
                    [
                      _c("markdown-editor", {
                        attrs: {
                          height: "400px",
                          mode: "wysiwyg",
                          options: { html: false }
                        },
                        model: {
                          value: _vm.temp.description,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "description", $$v)
                          },
                          expression: "temp.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "Данные" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Рассрочка", "label-width": "220px" } },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.temp.canInstallments,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "canInstallments", $$v)
                          },
                          expression: "temp.canInstallments"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Срок жизни", "label-width": "220px" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "100px" },
                        model: {
                          value: _vm.temp.life,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "life", $$v)
                          },
                          expression: "temp.life"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Цена", "label-width": "220px" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "100px" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.temp.price,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "price", _vm._n($$v))
                          },
                          expression: "temp.price"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Старая цена", "label-width": "220px" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "100px" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.temp.old_price,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "old_price", _vm._n($$v))
                          },
                          expression: "temp.old_price"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Окончание",
                        prop: "end_promo",
                        "label-width": "220px"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          format: "dd.MM.yyyy HH:mm:ss",
                          placeholder: "Выберите дату"
                        },
                        model: {
                          value: _vm.temp.end_promo,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "end_promo", $$v)
                          },
                          expression: "temp.end_promo"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Вес единицы", "label-width": "220px" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "100px" },
                        attrs: { type: "number", step: "any" },
                        model: {
                          value: _vm.temp.unit.weight,
                          callback: function($$v) {
                            _vm.$set(_vm.temp.unit, "weight", _vm._n($$v))
                          },
                          expression: "temp.unit.weight"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Размеры единицы (Ш х В х Г)",
                        "label-width": "220px"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "el-form-row" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0px" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100px" },
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.temp.unit.width,
                                  callback: function($$v) {
                                    _vm.$set(_vm.temp.unit, "width", $$v)
                                  },
                                  expression: "temp.unit.width"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "10px" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100px" },
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.temp.unit.height,
                                  callback: function($$v) {
                                    _vm.$set(_vm.temp.unit, "height", $$v)
                                  },
                                  expression: "temp.unit.height"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "10px" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100px" },
                                attrs: { type: "number", step: "0.01" },
                                model: {
                                  value: _vm.temp.unit.depth,
                                  callback: function($$v) {
                                    _vm.$set(_vm.temp.unit, "depth", $$v)
                                  },
                                  expression: "temp.unit.depth"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Вес короба", "label-width": "220px" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "100px" },
                        attrs: { type: "number", step: "any" },
                        model: {
                          value: _vm.temp.box.weight,
                          callback: function($$v) {
                            _vm.$set(_vm.temp.box, "weight", _vm._n($$v))
                          },
                          expression: "temp.box.weight"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Вместимость", "label-width": "220px" } },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "100px" },
                        attrs: { type: "number", step: "any" },
                        model: {
                          value: _vm.temp.box.units,
                          callback: function($$v) {
                            _vm.$set(_vm.temp.box, "units", _vm._n($$v))
                          },
                          expression: "temp.box.units"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.temp.box
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Размеры короба (Ш х В х Г)",
                            "label-width": "220px"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "el-form-row" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0px" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100px" },
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.temp.box.width,
                                      callback: function($$v) {
                                        _vm.$set(_vm.temp.box, "width", $$v)
                                      },
                                      expression: "temp.box.width"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "10px" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100px" },
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.temp.box.height,
                                      callback: function($$v) {
                                        _vm.$set(_vm.temp.box, "height", $$v)
                                      },
                                      expression: "temp.box.height"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "10px" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100px" },
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.temp.box.depth,
                                      callback: function($$v) {
                                        _vm.$set(_vm.temp.box, "depth", $$v)
                                      },
                                      expression: "temp.box.depth"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "Опт" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Оптовый товар", "label-width": "170px" }
                    },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.temp.isWholesale,
                          callback: function($$v) {
                            _vm.$set(_vm.temp, "isWholesale", $$v)
                          },
                          expression: "temp.isWholesale"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Цена", "label-width": "170px" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.temp.wholesale.price,
                          callback: function($$v) {
                            _vm.$set(_vm.temp.wholesale, "price", _vm._n($$v))
                          },
                          expression: "temp.wholesale.price"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Количество", "label-width": "170px" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.temp.wholesale.quantity,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.temp.wholesale,
                              "quantity",
                              _vm._n($$v)
                            )
                          },
                          expression: "temp.wholesale.quantity"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "Изображения" } }, [
                _c(
                  "div",
                  { staticStyle: { padding: "0 0 20px 0" } },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.images, "row-key": "id", fit: "" }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "left",
                            label: "Изображение товара",
                            "min-width": "150px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("div", { staticClass: "img-thumbnail" }, [
                                    _c("img", {
                                      attrs: { height: "100px", src: row.image }
                                    })
                                  ])
                                ]
                              }
                            }
                          ])
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { align: "center", width: "120" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      icon: "el-icon-edit"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleEdit(row)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("el-button", {
                                    attrs: {
                                      type: "danger",
                                      size: "mini",
                                      icon: "el-icon-trash"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleDelete(row)
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("table.cancel")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.buttonLoading,
                  expression: "buttonLoading"
                }
              ],
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.updateData()
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("table.confirm")) + "\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("SingleImageUpload", {
        attrs: { "on-confirm": _vm.onImageConfirm, query: _vm.query },
        model: {
          value: _vm.imageDialogVisible,
          callback: function($$v) {
            _vm.imageDialogVisible = $$v
          },
          expression: "imageDialogVisible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }