var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.visible },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "upload-container" },
        [
          _c(
            "el-upload",
            {
              staticClass: "image-uploader",
              attrs: {
                multiple: false,
                "show-file-list": false,
                "before-upload": _vm.beforeUpload,
                drag: "",
                action: "https://api.tuesok.shop/uploads",
                "http-request": _vm.feathersUpload
              }
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _vm._v(" "),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("\n        Перетащите файл сюда или "),
                _c("em", [_vm._v("нажмите, чтобы загрузить")])
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "image-preview" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tempUrl.length > 1,
                    expression: "tempUrl.length>1"
                  }
                ],
                staticClass: "image-preview-wrapper"
              },
              [_c("img", { attrs: { src: _vm.tempUrl } })]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        {
          on: {
            click: function($event) {
              _vm.visible = false
            }
          }
        },
        [_vm._v("\n    Отмена\n  ")]
      ),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
        [_vm._v("\n    Ок\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }