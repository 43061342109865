"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _feathersClient = _interopRequireDefault(require("@/api/feathers-client"));

var _vueImageUploadResize = _interopRequireDefault(require("vue-image-upload-resize"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'SingleImageUpload',
  components: {
    ImageUploader: _vueImageUploadResize.default
  },
  props: {
    query: {
      type: Object,
      default: function _default() {
        return Object.assign({});
      }
    },
    value: false,
    onConfirm: Function,
    path: String
  },
  data: function data() {
    return {
      file: File,
      hasSuccess: false,
      tempUrl: '',
      imageUrl: '',
      hasImage: false,
      prefix: {
        'standart': 'uploads',
        'banner': 'banners',
        'icon': 'icons'
      }
    };
  },
  computed: {
    mode: function mode() {
      return this.query.mode ? this.query.mode : 'standart';
    },
    visible: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    handleSubmit: function handleSubmit() {
      if (!this.hasSuccess) {
        this.$message('Подождите, пока изображение будут успешно загружено. Если возникли проблемы с сетью, обновите страницу и загрузите заново!');
        return;
      }

      this.onConfirm(this.imageUrl);
      this.visible = false;
    },
    beforeUpload: function beforeUpload(file) {
      this.file = file;
    },
    getImage: function getImage(image) {
      return "https://tuesok.shop/" + this.prefix[this.mode] + "/" + image;
    },
    setImage: function setImage(file) {
      var _this = this;

      this.hasImage = true;
      this.image = file;
      console.log('setImage', file);

      var uploadService = _feathersClient.default.service('/uploads');

      uploadService.create(Object.assign({}, this.query, {
        uri: file.dataUrl,
        id: file.info.name
      })).then(function (response) {
        _this.tempUrl = getImage(response.id);
        _this.imageUrl = response.id;
        _this.hasSuccess = true;
      }).catch(function (error) {
        console.log('Server error: ', error);
      });
    },
    feathersUpload: function feathersUpload(options) {
      var _this2 = this;

      var Upload = this.$FeathersVuex.Upload;
      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.addEventListener('load', function () {
        // console.log('feathersUpload', reader.result)  ;
        var uploadService = _feathersClient.default.service('uploads');

        var query = Object.assign({}, _this2.query, {
          uri: reader.result,
          id: _this2.file.name
        });
        console.log('query:', query);
        uploadService.create(Object.assign({}, _this2.query, {
          uri: reader.result,
          id: _this2.file.name
        })).then(function (response) {
          _this2.tempUrl = _this2.getImage(response.id); // this.tempUrl = "http://tuesok.shop/uploads/" + response.id

          _this2.imageUrl = response.id;
          _this2.hasSuccess = true;
          console.log('image', _this2.tempUrl, response.id);
        }).catch(function (error) {
          console.log('Server error: ', error);
        });
      }, false);
    }
  }
};
exports.default = _default2;