"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Todo',
  directives: {
    focus: function focus(el, _ref, _ref2) {
      var value = _ref.value;
      var context = _ref2.context;

      if (value) {
        context.$nextTick(function () {
          el.focus();
        });
      }
    }
  },
  props: {
    todo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      editing: false
    };
  },
  methods: {
    deleteTodo: function deleteTodo(todo) {
      this.$emit('deleteTodo', todo);
    },
    editTodo: function editTodo(_ref3) {
      var todo = _ref3.todo,
          value = _ref3.value;
      this.$emit('editTodo', {
        todo: todo,
        value: value
      });
    },
    toggleTodo: function toggleTodo(todo) {
      this.$emit('toggleTodo', todo);
    },
    doneEdit: function doneEdit(e) {
      var value = e.target.value.trim();
      var todo = this.todo;

      if (!value) {
        this.deleteTodo({
          todo: todo
        });
      } else if (this.editing) {
        this.editTodo({
          todo: todo,
          value: value
        });
        this.editing = false;
      }
    },
    cancelEdit: function cancelEdit(e) {
      e.target.value = this.todo.text;
      this.editing = false;
    }
  }
};
exports.default = _default2;