var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "row-class-name": _vm.tableRowClassName
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "icon", align: "center", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("i", { class: scope.row.icon })]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.showId
            ? _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.id"),
                  prop: "id",
                  sortable: "custom",
                  align: "center",
                  width: "300"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row._id))])]
                      }
                    }
                  ],
                  null,
                  false,
                  3630959700
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.code"),
              align: "center",
              width: "130"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.code))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.name"),
              prop: "name",
              "min-width": "100px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.name))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              align: "center",
              width: "160",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("table.delete")) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }