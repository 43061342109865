var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "material-input__component", class: _vm.computedClasses },
    [
      _c("div", { class: { iconClass: _vm.icon } }, [
        _vm.icon
          ? _c("i", {
              staticClass: "el-input__icon material-input__icon",
              class: ["el-icon-" + _vm.icon]
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "email"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentValue,
                  expression: "currentValue"
                }
              ],
              staticClass: "material-input",
              attrs: {
                name: _vm.name,
                placeholder: _vm.fillPlaceHolder,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
                autoComplete: _vm.autoComplete,
                required: _vm.required,
                type: "email"
              },
              domProps: { value: _vm.currentValue },
              on: {
                focus: _vm.handleMdFocus,
                blur: _vm.handleMdBlur,
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.currentValue = $event.target.value
                  },
                  _vm.handleModelInput
                ]
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "url"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentValue,
                  expression: "currentValue"
                }
              ],
              staticClass: "material-input",
              attrs: {
                name: _vm.name,
                placeholder: _vm.fillPlaceHolder,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
                autoComplete: _vm.autoComplete,
                required: _vm.required,
                type: "url"
              },
              domProps: { value: _vm.currentValue },
              on: {
                focus: _vm.handleMdFocus,
                blur: _vm.handleMdBlur,
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.currentValue = $event.target.value
                  },
                  _vm.handleModelInput
                ]
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "number"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentValue,
                  expression: "currentValue"
                }
              ],
              staticClass: "material-input",
              attrs: {
                name: _vm.name,
                placeholder: _vm.fillPlaceHolder,
                step: _vm.step,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
                autoComplete: _vm.autoComplete,
                max: _vm.max,
                min: _vm.min,
                minlength: _vm.minlength,
                maxlength: _vm.maxlength,
                required: _vm.required,
                type: "number"
              },
              domProps: { value: _vm.currentValue },
              on: {
                focus: _vm.handleMdFocus,
                blur: _vm.handleMdBlur,
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.currentValue = $event.target.value
                  },
                  _vm.handleModelInput
                ]
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "password"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentValue,
                  expression: "currentValue"
                }
              ],
              staticClass: "material-input",
              attrs: {
                name: _vm.name,
                placeholder: _vm.fillPlaceHolder,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
                autoComplete: _vm.autoComplete,
                max: _vm.max,
                min: _vm.min,
                required: _vm.required,
                type: "password"
              },
              domProps: { value: _vm.currentValue },
              on: {
                focus: _vm.handleMdFocus,
                blur: _vm.handleMdBlur,
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.currentValue = $event.target.value
                  },
                  _vm.handleModelInput
                ]
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "tel"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentValue,
                  expression: "currentValue"
                }
              ],
              staticClass: "material-input",
              attrs: {
                name: _vm.name,
                placeholder: _vm.fillPlaceHolder,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
                autoComplete: _vm.autoComplete,
                required: _vm.required,
                type: "tel"
              },
              domProps: { value: _vm.currentValue },
              on: {
                focus: _vm.handleMdFocus,
                blur: _vm.handleMdBlur,
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.currentValue = $event.target.value
                  },
                  _vm.handleModelInput
                ]
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "text"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentValue,
                  expression: "currentValue"
                }
              ],
              staticClass: "material-input",
              attrs: {
                name: _vm.name,
                placeholder: _vm.fillPlaceHolder,
                readonly: _vm.readonly,
                disabled: _vm.disabled,
                autoComplete: _vm.autoComplete,
                minlength: _vm.minlength,
                maxlength: _vm.maxlength,
                required: _vm.required,
                type: "text"
              },
              domProps: { value: _vm.currentValue },
              on: {
                focus: _vm.handleMdFocus,
                blur: _vm.handleMdBlur,
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.currentValue = $event.target.value
                  },
                  _vm.handleModelInput
                ]
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "material-input-bar" }),
        _vm._v(" "),
        _c("label", { staticClass: "material-label" }, [_vm._t("default")], 2)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }