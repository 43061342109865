var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "link--mallki", class: _vm.className, attrs: { href: "#" } },
    [
      _vm._v("\n  " + _vm._s(_vm.text) + "\n  "),
      _c("span", { attrs: { "data-letters": _vm.text } }),
      _vm._v(" "),
      _c("span", { attrs: { "data-letters": _vm.text } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }