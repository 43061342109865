"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _waves = _interopRequireDefault(require("@/directive/waves"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Waves directive
var ROOT = "00000000-0000-0000-0000-000000000000";
var _default = {
  name: 'Settlements',
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      listLoading: true,
      parent_row: {
        _id: ROOT
      },
      listQuery: {
        parent: ROOT,
        $sort: {
          'is_folder': 1,
          '_id': 1
        }
      },
      showId: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    setParent: function setParent(parent_row) {
      if (parent_row._id !== ROOT) {
        this.list.unshift(Object.assign({}, parent_row, {
          icon: 'el-icon-folder-opened'
        }));
        this.setParent(parent_row.parent_row);
      }
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      this.listQuery.parent = this.parent_row._id;
      this.$store.dispatch('settlements/find', {
        query: this.listQuery
      }).then(function (response) {
        _this.list = response.map(function (c) {
          var item = Object.assign({}, c, {
            parent_row: _this.parent_row
          });
          if (c.is_folder) Object.assign(item, {
            icon: 'el-icon-folder'
          });else Object.assign(item, {
            icon: 'el-icon-document'
          });
          return item;
        });

        _this.setParent(_this.parent_row); // if (this.parent_row._id !== ROOT) {
        //   this.$store.dispatch('items/get', this.parent_row._id).then(res => {
        //     const parent = Object.assign({}, res, { icon: 'el-icon-folder-opened', _id: res.parent, parent_row: this.parent_row })
        //     this.list.unshift(parent)
        //   })
        // }


        _this.total = response.total;
        _this.listLoading = false;
      });
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;

      if (row.icon === 'el-icon-folder-opened') {
        return 'parent-row';
      }

      return '';
    },
    handleUpdate: function handleUpdate(row) {
      if (row.is_folder) {
        if (row.icon === 'el-icon-folder-opened') {
          this.parent_row = row.parent_row;
          this.page = 1;
        } else {
          this.parent_row = row;
        }

        this.getList();
      }
    },
    deleteRecursively: function deleteRecursively(data) {
      var _this2 = this;

      if (data.is_folder) {
        this.$store.dispatch('settlements/find', {
          query: {
            parent: data._id
          }
        }).then(function (response) {
          response.data.map(function (item) {
            return _this2.deleteRecursively(item);
          });
        });
      }

      return this.$store.dispatch('settlements/remove', data._id);
    },
    deleteItem: function deleteItem(data) {
      var _this3 = this;

      this.deleteRecursively(data).then(function (response) {
        _this3.$message({
          message: 'Населенный пункт успешно удален',
          type: 'success'
        });

        _this3.getList();
      });
    },
    handleDelete: function handleDelete(data) {
      var _this4 = this;

      this.$confirm('Вы уверены, что хотите удалить населенный пункт?', {
        confirmButtonText: 'Ок',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(function (dialog) {
        return _this4.deleteItem(data);
      }).catch(console.log);
    }
  }
};
exports.default = _default;