var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "todo",
      class: { completed: _vm.todo.done, editing: _vm.editing }
    },
    [
      _c("div", { staticClass: "view" }, [
        _c("input", {
          staticClass: "toggle",
          attrs: { type: "checkbox" },
          domProps: { checked: _vm.todo.done },
          on: {
            change: function($event) {
              return _vm.toggleTodo(_vm.todo)
            }
          }
        }),
        _vm._v(" "),
        _c("label", {
          domProps: { textContent: _vm._s(_vm.todo.text) },
          on: {
            dblclick: function($event) {
              _vm.editing = true
            }
          }
        }),
        _vm._v(" "),
        _c("button", {
          staticClass: "destroy",
          on: {
            click: function($event) {
              return _vm.deleteTodo(_vm.todo)
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.editing,
            expression: "editing"
          },
          {
            name: "focus",
            rawName: "v-focus",
            value: _vm.editing,
            expression: "editing"
          }
        ],
        staticClass: "edit",
        domProps: { value: _vm.todo.text },
        on: {
          keyup: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.doneEdit($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.cancelEdit($event)
            }
          ],
          blur: _vm.doneEdit
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }