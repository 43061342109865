"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.string.starts-with");

var _feathersClient = _interopRequireDefault(require("@/api/feathers-client"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SingleImageUpload3',
  props: {
    article_id: String,
    filename: String,
    value: String // value: {
    //   id: String,
    //   type: String,
    //   default: ''
    // },

  },
  data: function data() {
    return {
      tempUrl: '',
      imageUrl: '',
      dataObj: {
        token: '',
        key: ''
      }
    };
  },
  computed: {
    image: function image() {
      if (this.imageUrl && !this.imageUrl.startsWith('http')) return 'http://tuesok.shop' + this.imageUrl;
      return this.imageUrl;
    }
  },
  watch: {
    value: function value(val) {
      this.imageUrl = val;
    }
  },
  methods: {
    rmImage: function rmImage() {
      this.emitInput('');
    },
    emitInput: function emitInput(val) {
      this.$emit('input', val);
    },
    beforeUpload: function beforeUpload(file) {
      this.file = file;
    },
    feathersUpload: function feathersUpload(options) {
      var _this = this;

      var Upload = this.$FeathersVuex.Upload;
      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.addEventListener('load', function () {
        var uploadService = _feathersClient.default.service('uploads');

        var query = Object.assign({}, _this.query, {
          uri: reader.result,
          id: _this.filename || _this.file.name,
          mode: 'article',
          article_id: _this.article_id
        });
        console.log('query:', query);
        uploadService.create(query).then(function (response) {
          console.log('response', response);
          _this.tempUrl = "http://tuesok.shop/uploads/" + response.id;
          _this.imageUrl = response.dir + response.id;

          _this.emitInput(_this.imageUrl);

          _this.hasSuccess = true;
        }).catch(function (error) {
          console.log('Server error: ', error);
        });
      }, false);
    }
  }
};
exports.default = _default;