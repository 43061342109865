"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Waves directive
var _default = {
  name: 'Banners',
  components: {
    SingleImageUpload: _singleImage.default
  },
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      listLoading: true,
      listQuery: {
        $sort: {
          'createdAt': 1
        }
      },
      showId: false,
      dialogFormVisible: false,
      rules: {
        image: [{
          required: true,
          message: 'image is required',
          trigger: 'blur'
        }]
      },
      textMap: {
        update: 'Редактирование',
        create: 'Создание'
      },
      dialogStatus: '',
      temp: {
        _id: undefined,
        image: '',
        url: '',
        begin: null,
        end: null
      },
      imageDialogVisible: false,
      hasSuccess: false,
      imageUrl: "",
      buttonLoading: false
    };
  },
  computed: {
    image: function image() {
      return 'https://tuesok.shop/banners/' + this.temp.image;
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      this.$store.dispatch('banners/find', {
        query: this.listQuery
      }).then(function (response) {
        _this.list = response.data;
        _this.total = response.total;
        _this.listLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.temp = {
        _id: null
      };
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this3 = this;

      this.temp = Object.assign({}, row);
      if (row.begin) this.temp.begin = new Date(row.begin);
      if (row.end) this.temp.end = new Date(row.end);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    deleteItem: function deleteItem(data) {
      var _this4 = this;

      this.$store.dispatch('banners/remove', data._id).then(function (response) {
        _this4.$message({
          message: 'Баннер успешно удален',
          type: 'success'
        });

        _this4.getList();
      });
    },
    handleDelete: function handleDelete(data) {
      var _this5 = this;

      this.$confirm('Вы уверены, что хотите баннер?', {
        confirmButtonText: 'Ок',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(function (dialog) {
        return _this5.deleteItem(data);
      }).catch(console.log);
    },
    updateData: function updateData() {
      var _this6 = this;

      this.buttonLoading = true;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          console.log(_this6.temp);
          if (_this6.temp.begin) _this6.temp.begin.setHours(0, 0, 0, 0);
          if (_this6.temp.end) _this6.temp.end.setHours(23, 59, 59, 999);
          var tempData = Object.assign({}, _this6.temp, {
            banner: true
          });
          var Banner = _this6.$FeathersVuex.api.Banner;
          var bannerModel = new Banner(tempData);
          console.log(tempData);
          bannerModel.save().then(function () {
            _this6.getList();

            _this6.dialogFormVisible = false;

            _this6.$notify({
              title: 'Успех',
              message: 'Обновлен успешно',
              type: 'success',
              duration: 2000
            });

            _this6.buttonLoading = false;
          }).catch(function (error) {
            _this6.buttonLoading = false;
            console.log(error);
          });
        }
      });
    },
    onImageConfirm: function onImageConfirm(imageUrl) {
      console.log('onImageConfirm', imageUrl);
      this.temp.image = imageUrl;
    },
    handleSubmit: function handleSubmit() {
      if (!this.hasSuccess) {
        this.$message('Подождите, пока все изображения будут успешно загружены. Если возникли проблемы с сетью, обновите страницу и загрузите заново!');
        return;
      }

      this.imageDialogVisible = false;
      this.hasSuccess = false;
    }
  }
};
exports.default = _default;