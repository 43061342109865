var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "Имя, email, телефон", clearable: "" },
            on: { clear: _vm.handleFilter },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                autocomplete: "off",
                clearable: "",
                filterable: "",
                remote: "",
                "reserve-keyword": "",
                placeholder: "Агент",
                "remote-method": _vm.onSearchAgent,
                loading: _vm.loadingAgent
              },
              on: { change: _vm.onSelectAgent },
              model: {
                value: _vm.agentsearch,
                callback: function($$v) {
                  _vm.agentsearch = $$v
                },
                expression: "agentsearch"
              }
            },
            _vm._l(_vm.agents, function(agent) {
              return _c("el-option", {
                key: agent._id,
                attrs: { label: agent.label, value: agent._id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-checkbox",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "15px" },
              on: {
                change: function($event) {
                  _vm.tableKey = _vm.tableKey + 1
                }
              },
              model: {
                value: _vm.showId,
                callback: function($$v) {
                  _vm.showId = $$v
                },
                expression: "showId"
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("table.id")) + "\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, border: "", fit: "" }
        },
        [
          _vm.showId
            ? _c("el-table-column", {
                attrs: { label: "id", width: "290px", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var user = ref.row
                        return [_c("span", [_vm._v(_vm._s(user._id))])]
                      }
                    }
                  ],
                  null,
                  false,
                  613137286
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Дата", width: "140px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var user = ref.row
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(user)
                          }
                        }
                      },
                      [_vm._v(_vm._s(user.createdAt.dateTime()))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "e-mail", "min-width": "100px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var user = ref.row
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(user)
                          }
                        }
                      },
                      [_vm._v(_vm._s(user.email))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Телефон", width: "100px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var user = ref.row
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(user)
                          }
                        }
                      },
                      [_vm._v(_vm._s(user.phone))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Наименование",
              "min-width": "200px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var user = ref.row
                  return [_c("span", [_vm._v(_vm._s(_vm.getName(user)))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              align: "center",
              width: "100",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var user = ref.row
                  return [
                    user.status != "deleted"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(user)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("table.delete")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.limit },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:limit": function($event) {
            _vm.limit = $event
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.savingData,
                  expression: "savingData"
                }
              ],
              ref: "dataForm",
              staticStyle: { width: "100%" },
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "left",
                "label-width": "150px",
                autocomplete: "off"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Заблокирован" } },
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.temp.isBlocked,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "isBlocked", $$v)
                      },
                      expression: "temp.isBlocked"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "E-mail", prop: "email" } },
                [
                  _c("el-input", {
                    ref: "dataFormEmail",
                    model: {
                      value: _vm.temp.email,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "email", $$v)
                      },
                      expression: "temp.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Телефон" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "phone", $$v)
                      },
                      expression: "temp.phone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Фамилия" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.lastname,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "lastname", $$v)
                      },
                      expression: "temp.lastname"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Имя" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.name,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "name", $$v)
                      },
                      expression: "temp.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Отчество" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.middlename,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "middlename", $$v)
                      },
                      expression: "temp.middlename"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "el-form-row" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Роль" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.temp.role,
                            callback: function($$v) {
                              _vm.$set(_vm.temp, "role", $$v)
                            },
                            expression: "temp.role"
                          }
                        },
                        _vm._l(_vm.roles, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "el-form-row" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.temp.wan,
                        callback: function($$v) {
                          _vm.$set(_vm.temp, "wan", $$v)
                        },
                        expression: "temp.wan"
                      }
                    },
                    [_vm._v("whatsapp")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.temp.showIcon,
                        callback: function($$v) {
                          _vm.$set(_vm.temp, "showIcon", $$v)
                        },
                        expression: "temp.showIcon"
                      }
                    },
                    [_vm._v("Иконки")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Пароль" } },
                [
                  _c(
                    "el-input",
                    {
                      key: _vm.passwordType,
                      ref: "password",
                      staticStyle: { width: "100%" },
                      attrs: { type: _vm.passwordType },
                      model: {
                        value: _vm.temp.password,
                        callback: function($$v) {
                          _vm.$set(_vm.temp, "password", $$v)
                        },
                        expression: "temp.password"
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "show-pwd",
                          attrs: { slot: "suffix" },
                          on: { click: _vm.showPwd },
                          slot: "suffix"
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              "icon-class":
                                _vm.passwordType === "password"
                                  ? "eye"
                                  : "eye-open"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Населенный пункт" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      options: _vm.settlements,
                      props: { expandTrigger: "hover" }
                    },
                    model: {
                      value: _vm.temp.settlementId,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "settlementId", $$v)
                      },
                      expression: "temp.settlementId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { "native-type": "submit" },
                      on: { click: _vm.createToken }
                    },
                    [_vm._v("Create Apikey")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { "native-type": "submit", type: "primary" },
                      on: { click: _vm.saveData }
                    },
                    [_vm._v("Ок")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v("Отмена")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }