"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("/opt/admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _vuex = require("vuex");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// Waves directive
var payments = {
  0: 'Robokassa',
  1: 'Наличные',
  2: 'Терминал',
  3: 'Сбербанк'
};
var _default = {
  name: 'Categories',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      tableKey: 0,
      page: 1,
      total: 0,
      limit: 10,
      listLoading: false,
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: 'Редактирование',
        create: 'Создание'
      },
      temp: {
        _id: undefined,
        name: '',
        parent: undefined
      },
      query: {
        $sort: {
          createdAt: -1
        }
      },
      ids: [],
      number: ''
    };
  },
  computed: _objectSpread(_objectSpread({
    internalQuery: function internalQuery() {
      return _objectSpread({
        _id: {
          $in: this.ids
        }
      }, this.query);
    },
    skip: function skip() {
      return (this.page - 1) * this.limit;
    },
    fetchQuery: function fetchQuery() {
      var query = {};
      if (this.number) query.number = this.number;
      return Object.assign(query, this.query, {
        $limit: this.limit,
        $skip: this.skip
      });
    },
    rules: function rules() {
      return {
        name: [{
          required: true,
          message: 'Наименование должно быть обязательно заполнено',
          trigger: 'blur'
        }]
      };
    }
  }, (0, _vuex.mapGetters)('payments', {
    findPaymentsInStore: 'find'
  })), (0, _vuex.mapGetters)('users', {
    getUser: 'get'
  })),
  methods: _objectSpread({
    handleFilter: function handleFilter() {
      this.page = 1;
    },
    getPaginationInfo: function getPaginationInfo(scope) {
      var queryInfo = scope.queryInfo,
          isFindPending = scope.isFindPending,
          pageInfo = scope.pageInfo;
      if (isFindPending) return;
      this.total = queryInfo.total;

      if (pageInfo && pageInfo.ids) {
        this.ids = pageInfo.ids;
      }
    },
    username: function username(user) {
      var username = '';
      if (user.lastname) username = user.lastname.trim();
      if (user.name) username += (username ? ' ' : '') + user.name.trim();
      if (user.middlename) username += (username ? ' ' : '') + user.middlename.trim();
      return username;
    },
    payment_type: function payment_type(type) {
      return payments[type];
    },
    resetTemp: function resetTemp() {
      this.temp = {
        _id: undefined,
        name: '',
        parent: undefined
      };
    },
    handleCreate: function handleCreate(data) {
      var _this = this;

      this.resetTemp();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.data = data || {
        id: 0,
        children: this.tree
      };
      this.$nextTick(function () {
        _this.$refs['dataForm'].clearValidate();

        _this.$refs['dataFormName'].focus();
      });
    },
    saveData: function saveData() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var tempData = Object.assign({}, _this2.temp);
          var Category = _this2.$FeathersVuex.Category;
          var categoryModel = new Category(tempData);
          categoryModel.save().then(function (res) {
            console.log('create result', res);

            if (_this2.dialogStatus === 'update') {
              _this2.data.label = res.name;
            } else {
              var newChild = {
                id: res._id,
                label: res.name,
                children: []
              };
              newChild.parent = _this2.data;
              if (!_this2.data.children) _this2.$set(data, 'children', []);

              _this2.data.children.push(newChild);

              _this2.$notify({
                message: 'Категория создана успешно',
                type: 'success',
                duration: 2000
              });
            }

            _this2.dialogFormVisible = false;
          });
        }
      });
    },
    handle1C: function handle1C(row) {
      this.$store.dispatch('payments/patch', [row._id, {
        unloaded: false
      }]);
    },
    handleUpdate: function handleUpdate(data) {
      var _this3 = this;

      this.temp = {
        _id: data.id,
        name: data.label,
        parent: data.parent.id
      };
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();

        _this3.$refs['dataFormName'].focus();
      });
    },
    handleDelete: function handleDelete(node, data) {
      var _this4 = this;

      this.$confirm('Вы уверены, что хотите удалить оплату?', {
        confirmButtonText: 'Ок',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(function (dialog) {
        return _this4.deletePayment(data._id);
      }).then(function (res) {
        return _this4.$notify({
          message: 'Заказ успешно удален',
          type: 'success',
          duration: 2000
        });
      }).catch(function (error) {
        return _this4.$notify({
          message: error,
          type: 'error',
          duration: 6000
        });
      });
    }
  }, (0, _vuex.mapActions)('payments', {
    findPayments: 'find',
    deletePayment: 'remove'
  }))
};
exports.default = _default;