"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeathersVuex = exports.models = exports.BaseModel = exports.makeServicePlugin = exports.makeAuthPlugin = exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _feathers = _interopRequireDefault(require("@feathersjs/feathers"));

var _socketioClient = _interopRequireDefault(require("@feathersjs/socketio-client"));

var _authenticationClient = _interopRequireDefault(require("@feathersjs/authentication-client"));

var _socket = _interopRequireDefault(require("socket.io-client"));

var _feathersHooksCommon = require("feathers-hooks-common");

var _feathersVuex2 = _interopRequireDefault(require("feathers-vuex"));

var socket = (0, _socket.default)('https://api.tuesok.shop', {
  transports: ['websocket']
});
var feathersClient = (0, _feathers.default)().configure((0, _socketioClient.default)(socket)).configure((0, _authenticationClient.default)({
  storage: window.localStorage
})).hooks({
  before: {
    all: [(0, _feathersHooksCommon.iff)(function (context) {
      return ['create', 'update', 'patch'].includes(context.method);
    }, (0, _feathersHooksCommon.discard)('__id', '__isTemp'))]
  }
});
var _default = feathersClient; // Setting up feathers-vuex

exports.default = _default;

var _feathersVuex = (0, _feathersVuex2.default)(feathersClient, {
  serverAlias: 'api',
  // optional for working with multiple APIs (this is the default value)
  idField: '_id',
  // Must match the id field in your database table/collection
  whitelist: ['$regex', '$options']
}),
    makeServicePlugin = _feathersVuex.makeServicePlugin,
    makeAuthPlugin = _feathersVuex.makeAuthPlugin,
    BaseModel = _feathersVuex.BaseModel,
    models = _feathersVuex.models,
    FeathersVuex = _feathersVuex.FeathersVuex;

exports.FeathersVuex = FeathersVuex;
exports.models = models;
exports.BaseModel = BaseModel;
exports.makeAuthPlugin = makeAuthPlugin;
exports.makeServicePlugin = makeServicePlugin;