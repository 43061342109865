"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("/opt/admin/node_modules/@babel/runtime/helpers/typeof");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/opt/admin/node_modules/core-js/modules/es6.array.iterator.js");

require("/opt/admin/node_modules/core-js/modules/es6.promise.js");

require("/opt/admin/node_modules/core-js/modules/es6.object.assign.js");

require("/opt/admin/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("./utils/prototype");

var _lang = _interopRequireDefault(require("./lang"));

require("./icons");

require("./permission");

require("./utils/errorLog");

var filters = _interopRequireWildcard(require("./filters"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// A modern alternative to CSS resets
// global css
// Internationalization
// icon
// permission control
// error log
// global filters
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
}); // register global utility filters.


Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;

_store.default.dispatch('auth/authenticate').catch(function () {}) // Render the app
.then(function () {
  // eslint-disable-next-line no-new
  new _vue.default({
    el: '#app',
    router: _router.default,
    store: _store.default,
    i18n: _lang.default,
    render: function render(h) {
      return h(_App.default);
    }
  });
});