"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.asyncRoutes = exports.constantRoutes = void 0;

var _typeof2 = _interopRequireDefault(require("/opt/admin/node_modules/@babel/runtime-corejs2/helpers/typeof"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

_vue.default.use(_vueRouter.default);
/* Layout */


/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
**/

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 * */
var constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/authredirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/errorPage/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/errorPage/401'));
    });
  },
  hidden: true
}, {
  path: '',
  component: _layout.default,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      noCache: true,
      affix: true
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/

exports.constantRoutes = constantRoutes;
var asyncRoutes = [{
  path: '/orders',
  component: _layout.default,
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/orders/index'));
      });
    },
    name: 'Orders hidden',
    meta: {
      title: 'orders',
      icon: 'orders',
      noCache: true
    },
    hidden: true
  }, {
    path: '/redirect/orders/list',
    name: 'Orders',
    meta: {
      title: 'orders',
      icon: 'orders',
      noCache: true
    }
  }]
}, {
  path: '/articles',
  component: _layout.default,
  redirect: '/articles/list',
  name: 'News',
  meta: {
    title: 'News',
    icon: 'example'
  },
  children: [{
    path: 'create',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/articles/create'));
      });
    },
    name: 'CreateArticle',
    meta: {
      title: 'createArticle',
      icon: 'edit'
    }
  }, {
    path: 'edit/:id(\[0-9a-f]+)',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/articles/edit'));
      });
    },
    name: 'EditArticle',
    meta: {
      title: 'editArticle',
      noCache: true,
      activeMenu: '/articles/list'
    },
    hidden: true
  }, {
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/articles/list'));
      });
    },
    name: 'Articles',
    meta: {
      title: 'articles',
      icon: 'list',
      noCache: true
    }
  }]
}, {
  path: '/catalog',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/catalog/index'));
      });
    },
    name: 'Catalog',
    meta: {
      title: 'catalog',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'index/:id(\[0-9-a-f]+)',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/product/index'));
      });
    },
    name: 'product',
    props: true,
    meta: {
      title: 'product',
      icon: 'table',
      noCache: true
    },
    hidden: true
  }]
}, // {
//   path: '/product',
//   component: Layout,
//   children: [
//     {
//       path: 'index/:id(\[0-9a-f]+)',
//       component: () => import('@/views/product/index'),
//       name: 'product',
//       props: true,
//       meta: { title: 'product', icon: 'table', noCache: true },
//       hidden: true
//     }
//   ]
// },
{
  path: '/users',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/index'));
      });
    },
    name: 'Users',
    meta: {
      title: 'users',
      icon: 'peoples',
      noCache: true
    }
  }]
}, {
  path: '/registers',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/registers/index'));
      });
    },
    name: 'Registers',
    meta: {
      title: 'registers',
      icon: 'registration',
      noCache: true
    }
  }]
}, {
  path: '/banners',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/banners/index'));
      });
    },
    name: 'Banners',
    meta: {
      title: 'banners',
      icon: 'ads',
      noCache: true
    }
  }]
}, {
  path: '/apikeys',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/apikeys/index'));
      });
    },
    name: 'Apikeys',
    meta: {
      title: 'apikeys',
      icon: 'keys',
      noCache: true
    }
  }]
}, {
  path: '/payments',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/payments/index'));
      });
    },
    name: 'Payments',
    meta: {
      title: 'payments',
      icon: 'payment',
      noCache: true
    }
  }]
}, {
  path: '/recoveries',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recoveries/index'));
      });
    },
    name: 'Recoveries',
    meta: {
      title: 'recoveries',
      icon: 'recovery',
      noCache: true
    }
  }]
}, {
  path: '/promocodes',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/promocodes/index'));
      });
    },
    name: 'Promocodes',
    meta: {
      title: 'promocodes',
      icon: 'promocode',
      noCache: true
    }
  }]
}, {
  path: '/settlements',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/settlements/index'));
      });
    },
    name: 'Settlements',
    meta: {
      title: 'settlements',
      icon: 'promocode',
      noCache: true
    }
  }]
}, {
  path: '/whatsapp',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/whatsapp/index'));
      });
    },
    name: 'Whatsapp',
    meta: {
      title: 'whatsapp',
      icon: 'wechat',
      noCache: true
    }
  }]
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;