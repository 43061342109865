"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/opt/admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _vuex = require("vuex");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  name: 'Recoveries',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      page: 1,
      total: 0,
      limit: 10,
      listLoading: false,
      query: {
        $sort: {
          date: -1
        }
      },
      ids: []
    };
  },
  computed: {
    internalQuery: function internalQuery() {
      return {
        _id: {
          $in: this.ids
        }
      };
    },
    skip: function skip() {
      return (this.page - 1) * this.limit;
    },
    fetchQuery: function fetchQuery() {
      return Object.assign({}, this.query, {
        $limit: this.limit,
        $skip: this.skip
      });
    }
  },
  methods: _objectSpread({
    getPaginationInfo: function getPaginationInfo(scope) {
      var queryInfo = scope.queryInfo,
          isFindPending = scope.isFindPending,
          pageInfo = scope.pageInfo;
      if (isFindPending) return;
      this.total = queryInfo.total;
      if (pageInfo && pageInfo.ids) this.ids = pageInfo.ids;
    },
    handleDelete: function handleDelete(node, data) {
      var _this = this;

      this.$confirm('Вы уверены, что хотите удалить запись?', {
        confirmButtonText: 'Ок',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(function (dialog) {
        return _this.deleteRecovery(data._id);
      }).then(function (res) {
        return _this.$notify({
          message: 'Запись успешно удалена',
          type: 'success',
          duration: 2000
        });
      }).catch(function (error) {
        return _this.$notify({
          message: error,
          type: 'error',
          duration: 6000
        });
      });
    }
  }, (0, _vuex.mapActions)('recoveries', {
    findRecoveries: 'find',
    deleteRecovery: 'remove'
  }))
};
exports.default = _default;