"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage3"));

var _MDinput = _interopRequireDefault(require("@/components/MDinput"));

var _Sticky = _interopRequireDefault(require("@/components/Sticky"));

var _validate = require("@/utils/validate");

var _article = require("@/api/article");

var _Warning = _interopRequireDefault(require("./Warning"));

var _Dropdown = require("./Dropdown");

var _crypto = _interopRequireDefault(require("crypto"));

var _feathersClient = _interopRequireDefault(require("@/api/feathers-client"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 粘性header组件
var defaultForm = {
  status: 'draft',
  title: '',
  content: '',
  content_short: '',
  // source_uri: '',
  image_uri: '',
  display_time: undefined,
  _id: undefined,
  // platforms: ['a-platform'],
  comment_disabled: false,
  importance: 0
};
var _default = {
  name: 'ArticleDetail',
  components: {
    Tinymce: _Tinymce.default,
    MDinput: _MDinput.default,
    Upload: _singleImage.default,
    Sticky: _Sticky.default,
    Warning: _Warning.default,
    CommentDropdown: _Dropdown.CommentDropdown,
    PlatformDropdown: _Dropdown.PlatformDropdown,
    SourceUrlDropdown: _Dropdown.SourceUrlDropdown
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    var _this = this;

    var validateRequire = function validateRequire(rule, value, callback) {
      if (value === '') {
        _this.$message({
          message: rule.field + 'заполните поле',
          type: 'error'
        });

        callback(new Error(rule.field + 'заполните поле'));
      } else {
        callback();
      }
    };

    var validateSourceUri = function validateSourceUri(rule, value, callback) {
      if (value) {
        if ((0, _validate.validURL)(value)) {
          callback();
        } else {
          _this.$message({
            message: 'URL заполнен неправильно',
            type: 'error'
          });

          callback(new Error('URL заполнен неправильно'));
        }
      } else {
        callback();
      }
    };

    return {
      id: undefined,
      postForm: Object.assign({}, defaultForm),
      loading: false,
      userListOptions: [],
      rules: {
        image_uri: [{
          validator: validateRequire
        }],
        title: [{
          validator: validateRequire
        }],
        content: [{
          validator: validateRequire
        }] // source_uri: [{ validator: validateSourceUri, trigger: 'blur' }]

      },
      tempRoute: {}
    };
  },
  computed: {
    contentShortLength: function contentShortLength() {
      return this.postForm.content_short.length;
    },
    displayTime: {
      get: function get() {
        return +new Date(this.postForm.display_time);
      },
      set: function set(val) {
        this.postForm.display_time = new Date(val);
      }
    }
  },
  mounted: function mounted() {
    console.log('created:', this.isEdit);

    if (this.isEdit) {
      this.id = this.$route.params && this.$route.params.id;
      this.fetchData(this.id);
    } else {
      this.id = 'f' + (+new Date()).toString(16) + _crypto.default.randomBytes(6).toString('hex');
      this.postForm._id = this.id;
      console.log('id:', this.id);
    } // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of the setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221


    this.tempRoute = Object.assign({}, this.$route);
  },
  watch: {},
  methods: {
    fetchData: function fetchData(id) {
      var _this2 = this;

      this.$store.dispatch('articles/get', id).then(function (response) {
        console.log('fetch', response);
        _this2.postForm = response;
      }).catch(function (err) {
        console.log(err);
      });
    },
    setTagsViewTitle: function setTagsViewTitle() {
      var title = 'Edit Article';
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.postForm.id)
      });
      this.$store.dispatch('tagsView/updateVisitedView', route);
    },
    setPageTitle: function setPageTitle() {
      var title = 'Edit Article';
      document.title = "".concat(title, " - ").concat(this.postForm.id);
    },
    saveArticle: function saveArticle(message) {
      var _this3 = this;

      console.log(this.postForm);

      if (this.postForm.content.length === 0 || this.postForm.title.length === 0) {
        this.$message({
          message: 'Пожалуйста, заполните заголовок и содержание',
          type: 'warning'
        });
        return;
      }

      this.$refs.postForm.validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          if (!_this3.postForm.display_time) _this3.postForm.display_time = new Date(); // const { Article } = this.$FeathersVuex.api
          // const articleModel = new Article(this.postForm)

          var articlesService = _feathersClient.default.service('articles');

          var promise = _this3.isEdit ? articlesService.patch(_this3.postForm._id, _this3.postForm) : articlesService.create(_this3.postForm); // articleModel.save().then(() => {

          promise.then(function () {
            _this3.$notify({
              title: 'Успех',
              message: message,
              type: 'success',
              duration: 2000
            });

            _this3.loading = false;
          }).catch(function (error) {
            _this3.loading = false;
            console.log(error);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitForm: function submitForm() {
      this.postForm.status = 'published';
      this.saveArticle('Статья успешо опубликована');
    },
    draftForm: function draftForm() {
      this.postForm.status = 'draft';
      this.saveArticle('Статья сохранена в черновик');
    },
    getRemoteUserList: function getRemoteUserList(query) {// searchUser(query).then(response => {
      //   if (!response.data.items) return
      //   this.userListOptions = response.data.items.map(v => v.name)
      // })
    }
  }
};
exports.default = _default;