"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _singleImage = _interopRequireDefault(require("@/components/Upload/singleImage"));

var _feathersClient = _interopRequireDefault(require("@/api/feathers-client"));

var _MarkdownEditor = _interopRequireDefault(require("@/components/MarkdownEditor"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Product',
  components: {
    SingleImageUpload: _singleImage.default,
    MarkdownEditor: _MarkdownEditor.default
  },
  data: function data() {
    return {
      id: '',
      name: '',
      temp: {
        id: undefined,
        code: '',
        name: '',
        description: '',
        life: 0,
        old_price: 0,
        unit: {
          weight: 0,
          width: 0,
          height: 0,
          depth: 0
        },
        box: {
          weight: 0,
          width: 0,
          height: 0,
          depth: 0,
          units: 0
        },
        image: '',
        isWholesale: false,
        wholesale: {
          price: 0,
          quantity: 0
        },
        canInstallments: false
      },
      rules: {
        name: [{
          required: true,
          message: 'name is required',
          trigger: 'blur'
        }]
      },
      query: {
        mode: 'normal'
      },
      file: File,
      imageDialogVisible: false,
      tempImage: '',
      hasSuccess: false,
      loadingImage: false,
      imageUrl: '',
      buttonLoading: false,
      price_type: '',
      imageMenu: [{
        title: 'Изменить'
      }, {
        title: 'Удалить'
      }],
      images: []
    };
  },
  computed: {
    image: function image() {
      // if (!this.temp.image) return IMAGES_PREFIX + "noimage.jpg"
      // return IMAGES_PREFIX + this.temp.image
      var ext = this.$store.state.webp ? '.webp' : '.jpeg';
      if (this.temp.image && this.temp.image.length > 0) return process.env.VUE_APP_IMAGES_PREFIX + 'mini/' + this.temp._id + ext;
      return process.env.VUE_APP_IMAGES_PREFIX + 'noimage.jpg';
    }
  },
  mounted: function mounted() {
    this.id = this.$route.params.id;
    this.fetchData(this.id);
  },
  methods: {
    resetTemp: function resetTemp() {
      this.temp = {
        id: undefined,
        timestamp: new Date(),
        image: '',
        description: ''
      };
    },
    handleUpdate: function handleUpdate(row) {
      var _this = this;

      this.temp = Object.assign({}, row); // copy obj

      this.$nextTick(function () {
        _this.$refs['dataForm'].clearValidate();
      });
    },
    fetchData: function fetchData(id) {
      var _this2 = this;

      this.$store.dispatch('items/get', id).then(function (response) {
        _this2.temp = Object.assign(_this2.temp, response, {
          id: id
        });
        _this2.images = [{
          id: 0,
          image: _this2.image
        }];
      }).catch(function (err) {
        console.log(err);
      });
    },
    updateData: function updateData() {
      var _this3 = this;

      this.buttonLoading = true;
      var data = this.temp;
      var form = 'dataForm';
      this.$refs[form].validate(function (valid) {
        if (valid) {
          var tempData = Object.assign({}, data);
          var Item = _this3.$FeathersVuex.api.Item;

          if (tempData.prices && tempData.prices.length > 0) {
            var res = tempData.prices.filter(function (p) {
              return p.id === _this3.price_type;
            });
            if (res.length > 0) res[0].price = tempData.price;
          }

          var itemModel = new Item(tempData);
          itemModel.patch().then(function (res) {
            _this3.$notify({
              title: 'Успех',
              message: 'Обновлен успешно',
              type: 'success',
              duration: 2000
            });

            _this3.buttonLoading = false;
          }).catch(function (error) {
            _this3.buttonLoading = false;
            console.log(error);

            _this3.$notify({
              title: 'Ошибка',
              message: error,
              type: 'error',
              duration: 8000
            });
          });
        }
      });
    },
    onImageConfirm: function onImageConfirm(imageUrl) {
      // console.log('onImageConfirm', imageUrl);
      this.temp.image = imageUrl;
    },
    showImageUploadDialog: function showImageUploadDialog(mode) {
      this.query.mode = mode;
      this.imageDialogVisible = true;
    },
    handleSubmit: function handleSubmit() {
      if (!this.hasSuccess) {
        this.$message('Подождите, пока все изображения будут успешно загружены. Если возникли проблемы с сетью, обновите страницу и загрузите заново!');
        return;
      }

      this.imageDialogVisible = false;
      this.hasSuccess = false; // this.temp.image = this.tempImage
    },
    feathersUpload: function feathersUpload() {
      var _this4 = this;

      // const { Upload } = this.$FeathersVuex
      var reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.addEventListener('load', function () {
        var uploadService = _feathersClient.default.service('uploads');

        uploadService.create({
          uri: reader.result,
          id: _this4.file.name
        }).then(function (response) {
          _this4.tempImage = process.env.VUE_APP_IMAGES_PREFIX + response.id;
          _this4.hasSuccess = true;
        }).catch(function (error) {
          console.log('Server error: ', error);
        });
      }, false);
    }
  }
};
exports.default = _default;