"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchList = fetchList;
exports.fetchArticle = fetchArticle;
exports.fetchPv = fetchPv;
exports.createArticle = createArticle;
exports.updateArticle = updateArticle;

var _request = _interopRequireDefault(require("@/utils/request"));

function fetchList(query) {
  return (0, _request.default)({
    url: '/article/list',
    method: 'get',
    params: query
  });
}

function fetchArticle(id) {
  return (0, _request.default)({
    url: '/article/detail',
    method: 'get',
    params: {
      id: id
    }
  });
}

function fetchPv(pv) {
  return (0, _request.default)({
    url: '/article/pv',
    method: 'get',
    params: {
      pv: pv
    }
  });
}

function createArticle(data) {
  return (0, _request.default)({
    url: '/article/create',
    method: 'post',
    data: data
  });
}

function updateArticle(data) {
  return (0, _request.default)({
    url: '/article/update',
    method: 'post',
    data: data
  });
}