"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.searchUser = searchUser;
exports.transactionList = transactionList;

var _request = _interopRequireDefault(require("@/utils/request"));

function searchUser(name) {
  return (0, _request.default)({
    url: '/search/user',
    method: 'get',
    params: {
      name: name
    }
  });
}

function transactionList(query) {
  return (0, _request.default)({
    url: '/transaction/list',
    method: 'get',
    params: query
  });
}