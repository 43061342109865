"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/opt/admin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _vueProgressiveImage = _interopRequireDefault(require("vue-progressive-image"));

var _app = _interopRequireDefault(require("./modules/app"));

var _errorLog = _interopRequireDefault(require("./modules/errorLog"));

var _permission = _interopRequireDefault(require("./modules/permission"));

var _tagsView = _interopRequireDefault(require("./modules/tagsView"));

var _settings = _interopRequireDefault(require("./modules/settings"));

var _user = _interopRequireDefault(require("./modules/user"));

var _getters = _interopRequireDefault(require("./getters"));

var _feathersClient = require("../api/feathers-client");

var _store = _interopRequireDefault(require("./store.auth"));

var requireModule = require.context('./services', false, /.js$/);

var servicePlugins = requireModule.keys().map(function (modulePath) {
  return requireModule(modulePath).default;
});

_vue.default.use(_vuex.default);

_vue.default.use(_feathersClient.FeathersVuex);

_vue.default.use(_vueProgressiveImage.default);

var store = new _vuex.default.Store({
  modules: {
    app: _app.default,
    errorLog: _errorLog.default,
    permission: _permission.default,
    tagsView: _tagsView.default,
    settings: _settings.default,
    user: _user.default
  },
  getters: _getters.default,
  plugins: [].concat((0, _toConsumableArray2.default)(servicePlugins), [_store.default]),
  state: {
    roles: []
  },
  mutations: {
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    }
  }
});
var _default = store;
exports.default = _default;