"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _GithubCorner = _interopRequireDefault(require("@/components/GithubCorner"));

var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));

var _LineChart = _interopRequireDefault(require("./components/LineChart"));

var _RaddarChart = _interopRequireDefault(require("./components/RaddarChart"));

var _PieChart = _interopRequireDefault(require("./components/PieChart"));

var _BarChart = _interopRequireDefault(require("./components/BarChart"));

var _TransactionTable = _interopRequireDefault(require("./components/TransactionTable"));

var _TodoList = _interopRequireDefault(require("./components/TodoList"));

var _BoxCard = _interopRequireDefault(require("./components/BoxCard"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
};
var _default = {
  name: 'DashboardAdmin',
  components: {
    GithubCorner: _GithubCorner.default,
    PanelGroup: _PanelGroup.default,
    LineChart: _LineChart.default,
    RaddarChart: _RaddarChart.default,
    PieChart: _PieChart.default,
    BarChart: _BarChart.default,
    TransactionTable: _TransactionTable.default,
    TodoList: _TodoList.default,
    BoxCard: _BoxCard.default
  },
  data: function data() {
    return {
      lineChartData: lineChartData.newVisitis
    };
  },
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type];
    }
  }
};
exports.default = _default;