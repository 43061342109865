"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/opt/admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

require("codemirror/lib/codemirror.css");

require("tui-editor/dist/tui-editor.css");

require("tui-editor/dist/tui-editor-contents.css");

var _tuiEditor2 = _interopRequireDefault(require("tui-editor"));

var _defaultOptions = _interopRequireDefault(require("./defaultOptions"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'MarddownEditor',
  props: {
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: false,
      default: function _default() {
        return 'markdown-editor-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '');
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return _defaultOptions.default;
      }
    },
    mode: {
      type: String,
      default: 'markdown'
    },
    height: {
      type: String,
      required: false,
      default: '300px'
    },
    language: {
      type: String,
      required: false,
      default: 'en_US' // https://github.com/nhnent/tui.editor/tree/master/src/js/langs

    }
  },
  data: function data() {
    return {
      editor: null
    };
  },
  computed: {
    editorOptions: function editorOptions() {
      var options = Object.assign({}, _defaultOptions.default, this.options);
      options.initialEditType = this.mode;
      options.height = this.height;
      options.language = this.language;
      return options;
    }
  },
  watch: {
    value: function value(newValue, preValue) {
      if (newValue !== preValue && newValue !== this.editor.getValue()) {
        this.editor.setValue(newValue);
      }
    },
    language: function language(val) {
      this.destroyEditor();
      this.initEditor();
    },
    height: function height(newValue) {
      this.editor.height(newValue);
    },
    mode: function mode(newValue) {
      this.editor.changeMode(newValue);
    }
  },
  mounted: function mounted() {
    this.initEditor();
  },
  destroyed: function destroyed() {
    this.destroyEditor();
  },
  methods: {
    initEditor: function initEditor() {
      var _this = this;

      this.editor = new _tuiEditor2.default(_objectSpread({
        el: document.getElementById(this.id)
      }, this.editorOptions));

      if (this.value) {
        this.editor.setValue(this.value);
      }

      this.editor.on('change', function () {
        _this.$emit('input', _this.editor.getValue());
      });
    },
    destroyEditor: function destroyEditor() {
      if (!this.editor) return;
      this.editor.off('change');
      this.editor.remove();
    },
    setValue: function setValue(value) {
      this.editor.setValue(value);
    },
    getValue: function getValue() {
      return this.editor.getValue();
    },
    setHtml: function setHtml(value) {
      this.editor.setHtml(value);
    },
    getHtml: function getHtml() {
      return this.editor.getHtml();
    }
  }
};
exports.default = _default2;