"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Promocodes',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: false,
      page: 1,
      listQuery: {
        $limit: 20,
        $skip: 0
      },
      users: [],
      temp: {
        userId: undefined
      },
      textMap: {
        update: 'Редактирование',
        create: 'Создание'
      },
      dialogStatus: '',
      dialogFormVisible: false,
      buttonLoading: false,
      loading: false
    };
  },
  created: function created() {
    this.getList(); // this.$store.dispatch('users/find', { query: { $limit: 100 } }).then(res => this.users = res.data)
  },
  watch: {
    page: function page(val) {
      this.listQuery.$skip = (this.page - 1) * this.listQuery.$limit;
    }
  },
  methods: {
    remoteMethod: function remoteMethod($search) {
      var _this = this;

      if ($search !== '') {
        this.loading = true;
        this.$store.dispatch('users/find', {
          query: {
            email: {
              $search: $search
            }
          }
        }).then(function (response) {
          _this.loading = false;
          _this.users = response.data;
        });
      } else {
        this.users = [];
      }
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      this.$store.dispatch('promocodes/find', {
        query: this.listQuery
      }).then(function (response) {
        _this2.list = response.data;
        _this2.total = response.total;
        _this2.listLoading = false;
      }).catch(function (error) {
        _this2.listLoading = false;
        console.log(error);
      });
    },
    handleModifyStatus: function handleModifyStatus(data) {
      var _this3 = this;

      this.$confirm('Вы уверены, что хотите удалить промокод?', {
        confirmButtonText: 'Ок',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(function (dialog) {
        return _this3.deletePromocode(data);
      }).catch(console.log);
    },
    deletePromocode: function deletePromocode(data) {
      var _this4 = this;

      this.$store.dispatch('promocodes/remove', data._id).then(function (response) {
        _this4.$message({
          message: 'Промокод успешно удален',
          type: 'success'
        });

        _this4.getList();
      });
    },
    handleCreate: function handleCreate() {
      var _this5 = this;

      this.temp = {
        userId: '',
        _id: null
      };
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this5.$refs['dataForm'].clearValidate();
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this6 = this;

      this.temp = Object.assign({}, row); // copy obj

      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this6.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this7 = this;

      this.buttonLoading = true;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var tempData = Object.assign({}, _this7.temp);
          console.log('updateData', tempData);
          var Promocode = _this7.$FeathersVuex.api.Promocode;
          var promocodeModel = new Promocode(tempData);
          promocodeModel.save().then(function () {
            _this7.getList();

            _this7.dialogFormVisible = false;

            _this7.$notify({
              title: 'Успех',
              message: 'Обновлено успешно',
              type: 'success',
              duration: 2000
            });

            _this7.buttonLoading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;