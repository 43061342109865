"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _auth = require("@/utils/auth");

// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  withCredentials: true,
  // 跨域请求时发送 cookies
  timeout: 5000 // request timeout

}); // request interceptor


service.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (_store.default.getters.token) {
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    config.headers['X-Token'] = (0, _auth.getToken)();
  }

  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug

  Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get information such as headers or status
 * Please return  response => response
*/

/**
 * 下面的注释为通过在response里，自定义code来标示请求状态
 * 当code返回如下情况则说明权限有问题，登出并返回到登录页
 * 如想通过 XMLHttpRequest 来状态码标识 逻辑可写在下面error中
 * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
 */
function (response) {
  var res = response.data;

  if (res.code !== 20000) {
    (0, _elementUi.Message)({
      message: res.message,
      type: 'error',
      duration: 5 * 1000
    }); // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;

    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      // 请自行在引入 MessageBox
      // import { Message, MessageBox } from 'element-ui'
      _elementUi.MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('user/resetToken').then(function () {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      });
    }

    return Promise.reject('error');
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug

  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;