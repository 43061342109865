"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  logs: []
};
var mutations = {
  ADD_ERROR_LOG: function ADD_ERROR_LOG(state, log) {
    state.logs.push(log);
  }
};
var actions = {
  addErrorLog: function addErrorLog(_ref, log) {
    var commit = _ref.commit;
    commit('ADD_ERROR_LOG', log);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;