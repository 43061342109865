"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// source:https://github.com/wemake-services/vue-material-input/blob/master/src/components/MaterialInput.vue
var _default = {
  name: 'MdInput',
  props: {
    /* eslint-disable */
    icon: String,
    name: String,
    type: {
      type: String,
      default: 'text'
    },
    value: [String, Number],
    placeholder: String,
    readonly: Boolean,
    disabled: Boolean,
    min: String,
    max: String,
    step: String,
    minlength: Number,
    maxlength: Number,
    required: {
      type: Boolean,
      default: true
    },
    autoComplete: {
      type: String,
      default: 'off'
    },
    validateEvent: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      currentValue: this.value,
      focus: false,
      fillPlaceHolder: null
    };
  },
  computed: {
    computedClasses: function computedClasses() {
      return {
        'material--active': this.focus,
        'material--disabled': this.disabled,
        'material--raised': Boolean(this.focus || this.currentValue) // has value

      };
    }
  },
  watch: {
    value: function value(newValue) {
      this.currentValue = newValue;
    }
  },
  methods: {
    handleModelInput: function handleModelInput(event) {
      var value = event.target.value;
      this.$emit('input', value);

      if (this.$parent.$options.componentName === 'ElFormItem') {
        if (this.validateEvent) {
          this.$parent.$emit('el.form.change', [value]);
        }
      }

      this.$emit('change', value);
    },
    handleMdFocus: function handleMdFocus(event) {
      this.focus = true;
      this.$emit('focus', event);

      if (this.placeholder && this.placeholder !== '') {
        this.fillPlaceHolder = this.placeholder;
      }
    },
    handleMdBlur: function handleMdBlur(event) {
      this.focus = false;
      this.$emit('blur', event);
      this.fillPlaceHolder = null;

      if (this.$parent.$options.componentName === 'ElFormItem') {
        if (this.validateEvent) {
          this.$parent.$emit('el.form.blur', [this.currentValue]);
        }
      }
    }
  }
};
exports.default = _default;