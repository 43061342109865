var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", [_vm._v("\n    Статус: " + _vm._s(_vm.state) + "\n  ")]),
    _vm._v(" "),
    !_vm.ready
      ? _c(
          "div",
          [
            _vm.qr ? _c("vue-qrcode", { attrs: { value: _vm.qr } }) : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "qr_text" }, [_vm._v(_vm._s(_vm.qr))])
          ],
          1
        )
      : _c("div", [_vm._v("\n    Whatsap bot запущен!\n  ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }