"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = {
  name: 'ArticleList',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'info',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: null,
      total: 0,
      page: 1,
      listLoading: true,
      listQuery: {
        $skip: 0,
        $limit: 20
      }
    };
  },
  watch: {
    page: function page(val) {
      this.listQuery.$skip = (this.page - 1) * this.listQuery.$limit;
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      this.$store.dispatch('articles/find', {
        query: this.listQuery
      }).then(function (response) {
        _this.list = response.data;
        _this.total = response.total;
        _this.listLoading = false;
      });
    }
  }
};
exports.default = _default;