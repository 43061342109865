"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/opt/admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _vuex = require("vuex");

var _validate = require("@/utils/validate");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  name: 'Login',
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!(0, _validate.validEmail)(value)) {
        callback(new Error('Пожалуйста, введите правильный адрес электронной почты'));
      } else {
        callback();
      }
    };

    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('Пароль не может быть менее 6 символов'));
      } else {
        callback();
      }
    };

    return {
      error: '',
      loginForm: {
        email: '',
        password: ''
      },
      loginRules: {
        email: [{
          required: true,
          trigger: 'blur',
          validator: validateEmail
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      loading: false,
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    },
    'loginForm.email': function loginFormEmail(val) {
      this.error = '';
    },
    'loginForm.password': function loginFormPassword(val) {
      this.error = '';
    }
  },
  mounted: function mounted() {
    if (this.loginForm.email === '') {
      this.$refs.email.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  methods: _objectSpread({
    showPwd: function showPwd() {
      var _this = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;

          _this2.$store.dispatch('auth/authenticate', _objectSpread({
            strategy: 'local'
          }, _this2.loginForm)) // this.authenticate({ strategy: 'local', ...this.loginForm })
          .then(function (res) {
            console.log('res', res, _this2.$store.state.auth);
            _this2.loading = false;

            if (_this2.$store.state.auth.user.role !== 'admin') {
              _this2.error = 'Ошибка! Доступ запрещен';

              _this2.$store.dispatch('auth/logout');
            } else {
              _this2.$router.push({
                path: _this2.redirect || '/'
              });
            }
          }).catch(function (error) {
            console.log("login error:", error);
            _this2.error = "Ошибка! Неверный логин или пароль";
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }, (0, _vuex.mapActions)('auth', ['authenticate']))
};
exports.default = _default;