"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueQrcode = _interopRequireDefault(require("@chenfengyuan/vue-qrcode"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Whatsapp',
  components: {
    VueQrcode: _vueQrcode.default
  },
  data: function data() {
    return {
      qr: null,
      ready: false,
      state: ''
    };
  },
  created: function created() {
    this.getQR();
    this.$FeathersVuex.api.Constant.on('patched', this.setQR);
    this.getChats();
  },
  methods: {
    getChats: function getChats() {},
    setQR: function setQR(constants) {
      console.log('constants', constants);
      this.ready = constants.wb_ready;
      this.qr = constants.wb_qr;
      this.state = constants.wb_state;
    },
    getQR: function getQR() {
      this.$store.dispatch('constants/get', 0).then(this.setQR);
    }
  }
};
exports.default = _default;