"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Registers',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      tableKey: 0,
      showId: false,
      list: null,
      total: 0,
      listLoading: true,
      page: 1,
      email: ''
    };
  },
  computed: {
    listQuery: function listQuery() {
      var query = {
        $limit: this.limit,
        $skip: (this.page - 1) * this.limit
      };
      if (this.email.length > 0) query.email = {
        $search: this.email
      };
      return query;
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      this.$store.dispatch('registers/find', {
        query: this.listQuery
      }).then(function (response) {
        _this.list = response.data;
        _this.total = response.total;
        _this.listLoading = false;
      }).catch(function (error) {
        _this.listLoading = false;
        console.log(error);
      });
    },
    handleDelete: function handleDelete(data) {
      var _this2 = this;

      this.$confirm('Вы уверены, что хотите удалить регистрацию?', {
        confirmButtonText: 'Ок',
        cancelButtonText: 'Отмена',
        type: 'warning'
      }).then(function (dialog) {
        return _this2.deleteRegister(data);
      }).catch(console.log);
    },
    deleteRegister: function deleteRegister(data) {
      var _this3 = this;

      this.$store.dispatch('registers/remove', data._id).then(function (response) {
        _this3.$message({
          message: 'Регистрация успешно удалена',
          type: 'success'
        });

        _this3.getList();
      });
    }
  }
};
exports.default = _default;