"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _feathersClient = require("@/api/feathers-client");

var _default = (0, _feathersClient.makeAuthPlugin)({
  userService: 'users'
});

exports.default = _default;