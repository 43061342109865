var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { width: "160px", align: "center", label: "Дата создания" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.createdAt,
                            "{d}.{m}.{y} {h}:{i}"
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              width: "160px",
              align: "center",
              label: "Дата публикации"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            scope.row.display_time,
                            "{d}.{m}.{y} {h}:{i}"
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "class-name": "status-col",
              label: "Статус",
              width: "110"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: _vm._f("statusFilter")(row.status) } },
                      [
                        _vm._v(
                          "\n          " + _vm._s(row.status) + "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { "min-width": "300px", label: "Заголовок" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "link-type",
                        attrs: { to: "/articles/edit/" + row._id }
                      },
                      [_c("span", [_vm._v(_vm._s(row.title))])]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "Действия", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      { attrs: { to: "/articles/edit/" + scope.row._id } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-edit"
                            }
                          },
                          [_vm._v("\n            Редактировать\n          ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.page,
          limit: _vm.listQuery.$limit
        },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "$limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }