"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.search");

var _default = {
  name: 'Authredirect',
  created: function created() {
    var hash = window.location.search.slice(1);

    if (window.localStorage) {
      window.localStorage.setItem('x-admin-oauth-code', hash);
      window.close();
    }
  },
  render: function render(h) {
    return h(); // avoid warning message
  }
};
exports.default = _default;