var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drawer-container" }, [
    _c("div", [
      _c("h3", { staticClass: "drawer-title" }, [
        _vm._v(_vm._s(_vm.$t("settings.title")))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.theme")))]),
          _vm._v(" "),
          _c("theme-picker", {
            staticStyle: {
              float: "right",
              height: "26px",
              margin: "-3px 8px 0 0"
            },
            on: { change: _vm.themeChange }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.tagsView")))]),
          _vm._v(" "),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.tagsView,
              callback: function($$v) {
                _vm.tagsView = $$v
              },
              expression: "tagsView"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.fixedHeader")))]),
          _vm._v(" "),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.fixedHeader,
              callback: function($$v) {
                _vm.fixedHeader = $$v
              },
              expression: "fixedHeader"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-item" },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("settings.sidebarLogo")))]),
          _vm._v(" "),
          _c("el-switch", {
            staticClass: "drawer-switch",
            model: {
              value: _vm.sidebarLogo,
              callback: function($$v) {
                _vm.sidebarLogo = $$v
              },
              expression: "sidebarLogo"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }