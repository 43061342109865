"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    value: {
      required: true,
      default: function _default() {
        return [];
      },
      type: Array
    }
  },
  data: function data() {
    return {
      platformsOptions: [{
        key: 'a-platform',
        name: 'a-platform'
      }, {
        key: 'b-platform',
        name: 'b-platform'
      }, {
        key: 'c-platform',
        name: 'c-platform'
      }]
    };
  },
  computed: {
    platforms: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  }
};
exports.default = _default2;