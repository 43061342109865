var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "Номер", clearable: "" },
            on: { clear: _vm.handleFilter },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.number,
              callback: function($$v) {
                _vm.number = $$v
              },
              expression: "number"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                placeholder: "Выберите населенный пункт",
                clearable: ""
              },
              model: {
                value: _vm.settlementId,
                callback: function($$v) {
                  _vm.settlementId = $$v
                },
                expression: "settlementId"
              }
            },
            _vm._l(_vm.settlements, function(group) {
              return _c(
                "el-option-group",
                { key: group._id, attrs: { label: group.groupName } },
                _vm._l(group.items, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                autocomplete: "off",
                clearable: "",
                filterable: "",
                remote: "",
                "reserve-keyword": "",
                placeholder: "Имя, email, телефон",
                "remote-method": _vm.onSearchUser,
                loading: _vm.loadingUser
              },
              on: { change: _vm.onSelectUser },
              model: {
                value: _vm.usersearch,
                callback: function($$v) {
                  _vm.usersearch = $$v
                },
                expression: "usersearch"
              }
            },
            _vm._l(_vm.users, function(user) {
              return _c("el-option", {
                key: user._id,
                attrs: { label: user.label, value: user._id }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                autocomplete: "off",
                clearable: "",
                filterable: "",
                remote: "",
                "reserve-keyword": "",
                placeholder: "Агент",
                "remote-method": _vm.onSearchAgent,
                loading: _vm.loadingAgent
              },
              on: { change: _vm.onSelectAgent },
              model: {
                value: _vm.agentsearch,
                callback: function($$v) {
                  _vm.agentsearch = $$v
                },
                expression: "agentsearch"
              }
            },
            _vm._l(_vm.agents, function(agent) {
              return _c("el-option", {
                key: agent._id,
                attrs: { label: agent.label, value: agent._id }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("FeathersVuexFind", {
        attrs: {
          service: "orders",
          query: _vm.internalQuery,
          "fetch-query": _vm.fetchQuery,
          "edit-scope": _vm.getPaginationInfo,
          watch: "fetchQuery"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var orders = ref.items
              return _c(
                "el-table",
                {
                  key: _vm.tableKey,
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: orders,
                    border: "",
                    "row-class-name": _vm.tableRowClassName
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(props) {
                            return [
                              _c("div", { staticClass: "order__info" }, [
                                _c("div", { staticClass: "board__table" }, [
                                  _c("div", { staticClass: "board__row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "board__cell board__cell_th"
                                      },
                                      [_vm._v("Order ID")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "board__cell board__cell_th"
                                      },
                                      [_vm._v(_vm._s(props.row._id))]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "board__row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "board__cell board__cell_th"
                                      },
                                      [_vm._v("Payment Type")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "board__cell board__cell_th"
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { placeholder: "Выберите" },
                                            on: {
                                              change: function(e) {
                                                return _vm.onSelectPaymentType(
                                                  props.row,
                                                  e
                                                )
                                              }
                                            },
                                            model: {
                                              value: props.row.payment_type,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  props.row,
                                                  "payment_type",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "props.row.payment_type"
                                            }
                                          },
                                          _vm._l(_vm.selectPayment, function(
                                            item
                                          ) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "board__row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "board__cell board__cell_th"
                                      },
                                      [_vm._v("Payment ID")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "board__cell board__cell_th"
                                      },
                                      [_vm._v(_vm._s(props.row.payment_id))]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "board__row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "board__cell board__cell_th"
                                      },
                                      [_vm._v("Payment Amount")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "board__cell board__cell_th"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            (
                                              (props.row.payment_amount || 0) /
                                              100
                                            ).toFixed(2)
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "board__cell board__cell_th"
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "danger"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleGetPaymentAmount(
                                                  props.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Проверить оплату\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "board__row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "board__cell board__cell_th"
                                      },
                                      [_vm._v("Тип доставки")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "board__cell board__cell_th"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.deliveryType(
                                              props.row.delivery_type
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    data: props.row.items,
                                    border: "",
                                    "show-summary": "",
                                    "summary-method": _vm.getSummaries
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "Товар", align: "left" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  class: {
                                                    deleted: row.deleted
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      row.item
                                                        ? row.item.name
                                                        : ""
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { label: "Склад", align: "left" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  class: {
                                                    deleted: row.deleted
                                                  }
                                                },
                                                [_vm._v(_vm._s(row.store))]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Цена",
                                      align: "center",
                                      width: "120"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  class: {
                                                    deleted: row.deleted
                                                  }
                                                },
                                                [_vm._v(_vm._s(row.price))]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Количество",
                                      align: "center",
                                      width: "120"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  class: {
                                                    deleted: row.deleted
                                                  }
                                                },
                                                [_vm._v(_vm._s(row.quantity))]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Сумма",
                                      align: "center",
                                      width: "120",
                                      prop: "amount"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  class: {
                                                    deleted: row.deleted
                                                  }
                                                },
                                                [_vm._v(_vm._s(row.amount))]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "80",
                                      "class-name": "small-padding fixed-width"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "danger"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleDeleteRow(
                                                        props.row,
                                                        scope.$index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t("table.delete")
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "1C", align: "center", width: "50" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("div", {
                                staticClass: "circle",
                                class: { green: row.unloaded }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Дата", align: "center", width: "140" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.date.dateTime()))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Номер", align: "center", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var order = ref.row
                            return [_c("span", [_vm._v(_vm._s(order.number))])]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Статус заказа",
                      align: "center",
                      width: "126"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var order = ref.row
                            return [
                              _c("span", [_vm._v(_vm._s(_vm.status(order)))])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Тип оплаты",
                      align: "center",
                      width: "126"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var order = ref.row
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.payment_type(order.payment_type))
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Статус оплаты",
                      align: "center",
                      width: "126"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var order = ref.row
                            return [
                              _c("span", [_vm._v(_vm._s(_vm.payStatus(order)))])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Сумма", align: "right", width: "90" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var order = ref.row
                            return [
                              _c("span", [
                                _vm._v(_vm._s(order.total.toFixed(2)))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Доставка", align: "right", width: "90" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var order = ref.row
                            return [
                              _c("span", [
                                _vm._v(_vm._s(order.delivery.toFixed(2)))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Телефон", align: "center", width: "140" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var order = ref.row
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.phone(order.user.phone)))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Клиент", align: "left" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var order = ref.row
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.getName(order.user)))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Адрес", align: "left" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var order = ref.row
                            return [
                              _c("span", [_vm._v(_vm._s(_vm.address(order)))])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("table.actions"),
                      align: "center",
                      width: "120",
                      "class-name": "small-padding fixed-width"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-dropdown",
                                {
                                  on: {
                                    command: function(command) {
                                      return _vm.handleCommand(
                                        command,
                                        scope.row
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" }
                                    },
                                    [
                                      _vm._v("\n              Действия"),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right"
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "unload1C" } },
                                        [_vm._v("Выгрузить в 1С")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "cancelOrder" } },
                                        [_vm._v("Отменить заказ")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "cancelPayment" } },
                                        [_vm._v("Отменить оплату")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "deleteOrder" } },
                                        [_vm._v("Удалить заказ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.limit },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:limit": function($event) {
            _vm.limit = $event
          },
          pagination: _vm.findOrders
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "100%" },
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "left",
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "id", prop: "_id" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp._id,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "_id", $$v)
                      },
                      expression: "temp._id"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Наименование", prop: "name" } },
                [
                  _c("el-input", {
                    ref: "dataFormName",
                    model: {
                      value: _vm.temp.name,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "name", $$v)
                      },
                      expression: "temp.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", "native-type": "submit" },
                      on: { click: _vm.saveData }
                    },
                    [_vm._v("Ок")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v("Отмена")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }