"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/opt/admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _vue = _interopRequireDefault(require("vue"));

var _vueI18n = _interopRequireDefault(require("vue-i18n"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));

var _ruRU = _interopRequireDefault(require("element-ui/lib/locale/lang/ru-RU"));

var _en2 = _interopRequireDefault(require("./en"));

var _ru = _interopRequireDefault(require("./ru"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

_vue.default.use(_vueI18n.default);

var messages = {
  en: _objectSpread(_objectSpread({}, _en2.default), _en.default),
  ru: _objectSpread(_objectSpread({}, _ru.default), _ruRU.default)
};
var i18n = new _vueI18n.default({
  // set locale
  // options: en | ru
  locale: _jsCookie.default.get('language') || 'ru',
  // set locale messages
  messages: messages
});
var _default = i18n;
exports.default = _default;