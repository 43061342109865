var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("table.name"), clearable: "" },
            on: { clear: _vm.handleFilter },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("table.search")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-checkbox",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "15px" },
              on: {
                change: function($event) {
                  _vm.tableKey = _vm.tableKey + 1
                }
              },
              model: {
                value: _vm.showId,
                callback: function($$v) {
                  _vm.showId = $$v
                },
                expression: "showId"
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("table.id")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-checkbox",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "15px" },
              on: {
                change: function($event) {
                  _vm.tableKey = _vm.tableKey + 1
                }
              },
              model: {
                value: _vm.showCode,
                callback: function($$v) {
                  _vm.showCode = $$v
                },
                expression: "showCode"
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("table.code")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-clipboard" },
              on: {
                click: function($event) {
                  _vm.replacementFormVisible = true
                }
              }
            },
            [_vm._v("\n      Замена товара\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          ref: "catalogTable",
          staticClass: "mainCatalog",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "row-class-name": _vm.tableRowClassName
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "icon", align: "center", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("i", { class: scope.row.icon })]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.showId
            ? _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.id"),
                  prop: "id",
                  sortable: "custom",
                  align: "center",
                  width: "300"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row._id))])]
                      }
                    }
                  ],
                  null,
                  false,
                  3630959700
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCode
            ? _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.code"),
                  align: "center",
                  width: "130"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "link-type",
                              on: {
                                click: function($event) {
                                  return _vm.clickItem(scope.row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(scope.row.code))]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3995252750
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Изображение",
              align: "center",
              width: "120",
              "class-name": "img-content"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("img", {
                      staticClass: "img-thumbnail",
                      attrs: { src: _vm.get_image(scope.row) },
                      on: {
                        click: function($event) {
                          return _vm.clickItem(scope.row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            staticClass: "flex",
            attrs: {
              label: _vm.$t("table.name"),
              prop: "name",
              "min-width": "100px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.is_folder && scope.row.image
                      ? _c("div", { staticClass: "catalog__icon" }, [
                          _c("img", {
                            attrs: { src: _vm.icon_item(scope.row) }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        on: {
                          click: function($event) {
                            return _vm.clickItem(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.name))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.price"),
              prop: "price",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        on: {
                          click: function($event) {
                            return _vm.clickItem(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.price))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              align: "center",
              width: "160",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("table.delete")) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("table.edit")) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.listQuery.limit },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Редактирование каталога",
            visible: _vm.catalogFormVisible,
            width: "700px"
          },
          on: {
            "update:visible": function($event) {
              _vm.catalogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataFormCatalog",
              staticStyle: { width: "100%", height: "300px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.catalog,
                "label-position": "left",
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("table.code") } },
                [
                  _c("el-input", {
                    staticStyle: { width: "140px" },
                    attrs: { disabled: true },
                    model: {
                      value: _vm.catalog.code,
                      callback: function($$v) {
                        _vm.$set(_vm.catalog, "code", $$v)
                      },
                      expression: "catalog.code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("table.name"), prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "525px" },
                    model: {
                      value: _vm.catalog.name,
                      callback: function($$v) {
                        _vm.$set(_vm.catalog, "name", $$v)
                      },
                      expression: "catalog.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("table.image"), prop: "image" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "525px" },
                    model: {
                      value: _vm.catalog.image,
                      callback: function($$v) {
                        _vm.$set(_vm.catalog, "image", $$v)
                      },
                      expression: "catalog.image"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "el-from-item-row", attrs: { label: "Иконка" } },
                [
                  _c(
                    "div",
                    { staticClass: "button-row" },
                    [
                      _vm.catalog.image
                        ? _c("div", { staticClass: "icon-center" }, [
                            _c("img", {
                              attrs: {
                                height: "36px",
                                src: _vm.icon_item(_vm.catalog)
                              }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "upload-button",
                          attrs: {
                            icon: "el-icon-upload",
                            size: "mini",
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.showImageUploadDialog("icon")
                            }
                          }
                        },
                        [_vm._v("\n            Загрузить иконку\n          ")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.catalogFormVisible = false
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("table.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.buttonLoading,
                      expression: "buttonLoading"
                    }
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.updateData()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("table.confirm")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Замена товара",
            visible: _vm.replacementFormVisible,
            width: "700px"
          },
          on: {
            "update:visible": function($event) {
              _vm.replacementFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataFormReplacement",
              staticStyle: { width: "100%", height: "300px" },
              attrs: {
                rules: _vm.replacementRules,
                model: _vm.replacement,
                "label-position": "left",
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "id from" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "525px" },
                    on: { input: _vm.onInputIdFrom },
                    model: {
                      value: _vm.replacement.idFrom,
                      callback: function($$v) {
                        _vm.$set(_vm.replacement, "idFrom", $$v)
                      },
                      expression: "replacement.idFrom"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "name from" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "525px" },
                    attrs: { readonly: true },
                    model: {
                      value: _vm.replacement.nameFrom,
                      callback: function($$v) {
                        _vm.$set(_vm.replacement, "nameFrom", $$v)
                      },
                      expression: "replacement.nameFrom"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "id to" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "525px" },
                    on: { input: _vm.onInputIdTo },
                    model: {
                      value: _vm.replacement.idTo,
                      callback: function($$v) {
                        _vm.$set(_vm.replacement, "idTo", $$v)
                      },
                      expression: "replacement.idTo"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "name to" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "525px" },
                    attrs: { readonly: true },
                    model: {
                      value: _vm.replacement.nameTo,
                      callback: function($$v) {
                        _vm.$set(_vm.replacement, "nameTo", $$v)
                      },
                      expression: "replacement.nameTo"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Записать заказы" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.replacement.saveOrders,
                        callback: function($$v) {
                          _vm.$set(_vm.replacement, "saveOrders", $$v)
                        },
                        expression: "replacement.saveOrders"
                      }
                    },
                    [_vm._v("Записать заказы")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.replacementFormVisible = false
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("table.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.buttonLoading,
                      expression: "buttonLoading"
                    }
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.replaceGoods()
                    }
                  }
                },
                [_vm._v("\n        Выполнить\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("SingleImageUpload", {
        attrs: { "on-confirm": _vm.onImageConfirm, query: _vm.query },
        model: {
          value: _vm.imageDialogVisible,
          callback: function($$v) {
            _vm.imageDialogVisible = $$v
          },
          expression: "imageDialogVisible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }