"use strict";

var _interopRequireDefault = require("/opt/admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ArticleDetail = _interopRequireDefault(require("./components/ArticleDetail"));

//
//
//
//
var _default = {
  name: 'CreateArticle',
  components: {
    ArticleDetail: _ArticleDetail.default
  }
};
exports.default = _default;