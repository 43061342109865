var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { attrs: { "show-timeout": 100, trigger: "click" } },
    [
      _c("el-button", { attrs: { plain: "" } }, [
        _vm._v("\n    Link\n    "),
        _c("i", { staticClass: "el-icon-caret-bottom el-icon--right" })
      ]),
      _vm._v(" "),
      _c(
        "el-dropdown-menu",
        {
          staticClass: "no-padding no-border",
          staticStyle: { width: "400px" },
          attrs: { slot: "dropdown" },
          slot: "dropdown"
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-bottom": "0px" },
              attrs: { "label-width": "0px", prop: "source_uri" }
            },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "Please enter the content" },
                  model: {
                    value: _vm.source_uri,
                    callback: function($$v) {
                      _vm.source_uri = $$v
                    },
                    expression: "source_uri"
                  }
                },
                [
                  _c("template", { slot: "prepend" }, [
                    _vm._v("\n          URL\n        ")
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }