"use strict";

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.split");

/* eslint-disable no-extend-native */
String.prototype.toLocal = function () {
  var two = function two(s) {
    return ('0' + s).slice(-2);
  };

  var d = new Date(this);
  return two(d.getDate()) + '.' + two(d.getMonth() + 1) + '.' + two(d.getFullYear());
};

String.prototype.dateTime = function () {
  var two = function two(s) {
    return ('0' + s).slice(-2);
  };

  var d = new Date(this);
  return two(d.getDate()) + '.' + two(d.getMonth() + 1) + '.' + two(d.getFullYear()) + ' ' + two(d.getHours()) + ':' + two(d.getMinutes()) + ':' + two(d.getSeconds());
};

function number_format(number, decimals, dec_point, thousands_sep) {
  var n = !isFinite(+number) ? 0 : +number;
  var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  var sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep;
  var dec = typeof dec_point === 'undefined' ? '.' : dec_point;

  var toFixedFix = function toFixedFix(n, prec) {
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    var k = Math.pow(10, prec);
    return Math.round(n * k) / k;
  };

  var s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }

  return s.join(dec);
}

Number.prototype.rub = function () {
  var kop = Math.round((this - Math.floor(this)) * 100);
  var str = number_format(this, kop > 0 ? 2 : 0, '.', "\xA0");
  return str + "\xA0\u20BD";
}; //
// if (!String.prototype.startsWith) {
//   Object.defineProperty(String.prototype, 'startsWith', {
//     enumerable: false,
//     configurable: false,
//     writable: false,
//     value: function(searchString, position) {
//       position = position || 0;
//       return this.indexOf(searchString, position) === position;
//     }
//   });
// }