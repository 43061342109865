var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-upload",
        {
          staticClass: "image-uploader",
          attrs: {
            data: _vm.dataObj,
            multiple: false,
            "show-file-list": false,
            "before-upload": _vm.beforeUpload,
            drag: "",
            action: "http://api.tuesok.shop/uploads",
            "http-request": _vm.feathersUpload
          }
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _vm._v(" "),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v("\n      Перетащите файл сюда или "),
            _c("em", [_vm._v("нажмите кнопку Загрузить")])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "image-preview image-app-preview" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.image.length > 1,
                expression: "image.length>1"
              }
            ],
            staticClass: "image-preview-wrapper"
          },
          [
            _c("img", { attrs: { src: _vm.image } }),
            _vm._v(" "),
            _c("div", { staticClass: "image-preview-action" }, [
              _c("i", {
                staticClass: "el-icon-delete",
                on: { click: _vm.rmImage }
              })
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "image-preview" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.image.length > 1,
                expression: "image.length>1"
              }
            ],
            staticClass: "image-preview-wrapper"
          },
          [
            _c("img", { attrs: { src: _vm.image } }),
            _vm._v(" "),
            _c("div", { staticClass: "image-preview-action" }, [
              _c("i", {
                staticClass: "el-icon-delete",
                on: { click: _vm.rmImage }
              })
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }